import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import httpClient from './httpClient'

export class regionGroup extends BaseService {
  static get entity() {
    return 'regiongroup'
  }
  static async getRegionGroupById(Id) {
    try {
      const response = await httpClient.get(`${this.entity}/findbyid/${Id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getAllRegionGroups() {
    try {
      const response = await httpClient.get(`${this.entity}/getallregiongroups`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getAllRegionGroups() {
    try {
      const response = await httpClient.get(`${this.entity}/getallregiongroups`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {}
  }
  static async getAllRegionGroupsWithNumberOfSites() {
    try {
      const response = await httpClient.get(
        `${this.entity}/getallregiongroupswithsitecount`
      )
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getAllRegionGroupsWithAllSites() {
    try {
      const response = await httpClient.get(
        `${this.entity}/getallregiongroupswithsites`
      )
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createRegionGroup(data) {
    try {
      const response = await httpClient.post(`${this.entity}/create`, data)
      return new ResponseWrapper(response)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async updateRegionGroup(data) {
    try {
      const response = await httpClient.post(`${this.entity}/update`, data)
      return new ResponseWrapper(response)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async deleteRegionGroup(data) {
    try {
      const response = await httpClient.post(`${this.entity}/delete`, data)
      return new ResponseWrapper(response, {}, response.data.message)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
