import { assert } from '@core/assert'
import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import httpClient from './httpClient'

export class contractor extends BaseService {
  static get entity() {
    return 'contractor'
  }
  static async getContractorById(Id) {
    try {
      const response = await httpClient.get(`${this.entity}/findbyid/${Id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getContractors() {
    try {
      const response = await httpClient.get(`${this.entity}/getallcontractors`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createContractor(data) {
    try {
      const response = await httpClient.post(`${this.entity}/create`, data)
      return new ResponseWrapper(response)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async updateContractor(data) {
    try {
      const response = await httpClient.post(`${this.entity}/update`, data)
      return new ResponseWrapper(response)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async addPermissions(data) {
    try {
      const response = await httpClient.post(
        `${this.entity}/addresources`,
        data
      )
      return new ResponseWrapper(response)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async updateDetails(data) {
    try {
      const response = await httpClient.post(
        `${this.entity}/updatedetails`,
        data
      )
      return new ResponseWrapper(response)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async deleteContractor(data) {
    try {
      const response = await httpClient.post(`${this.entity}/delete`, data)
      return new ResponseWrapper(response, {}, response.data.message)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getFormattedSites() {
    try {
      const response = await httpClient.get(`${this.entity}/getformattedsites`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getFormattedSitesForContractor(Id) {
    try {
      const response = await httpClient.get(
        `${this.entity}/getformattedsitesforcontractor/${Id}`
      )
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async checkUnique(data) {
    try {
      const response = await httpClient.post(`${this.entity}/checkunique`, data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async removeResource(data) {
    try {
      const response = await httpClient.post(
        `${this.entity}/removeresource`,
        data
      )
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
