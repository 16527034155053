<template>
  <div class="row">
    <!-- Camera Table -->
    <div class="col-12">
      <div class="vue-form-wizard mb-3 md">
        <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
          <div class="d-flex align-items-center mb-1 mt-1">
            <feather-icon icon="CameraIcon" size="25" class="ml-1 mr-1" />
            <h3 class="">Cameras</h3>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'actions'">
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="text-body align-middle mr-1 trash-icon"
                @click="showDeleteModal(props.row)"
              />
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap mb-1 ml-1"> Show</span>

                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20', '50', '100']"
                  class="mx-1 mb-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-1 mr-1"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>

    <!-- Deletion Modal -->
    <b-modal
      id="delete-modal"
      hide-footer
      centered
      @hidden="clearDeleteModal()"
    >
      <template #modal-title> Delete Camera</template>
      <div class="d-block text-center">
        <h3>Are you sure you want to delete: {{ cameraToDelete.name }}?</h3>
      </div>
      <div class="mt-2" align="right">
        <b-button variant="primary" @click="$bvModal.hide('delete-modal')"
          >Cancel</b-button
        >
        <b-button class="ml-1" variant="outline-danger" @click="DeleteCamera()"
          >Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BSidebar,
  BCard,
  BCardBody,
  BCardTitle,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle,
  BForm,
  BRow,
  BCol,
  BDropdownGroup,
  BFormCheckbox,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import vSelect from 'vue-select'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// import Services
import { regionGroup } from '@/services/regionGroup.service.js'
import { contractor } from '@/services/contractor.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

// Declare component
export default {
  name: 'CameraTable',
  prop: ['contractor'],
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BButton,
    VBToggle,
    BModal,
    BBadge,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BSidebar,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownGroup,
    BFormCheckbox,
    FeatherIcon,
    BFormInvalidFeedback,
    vSelect
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      cameraToDelete: {
        name: '',
        url: '',
        img: ''
      },
      newCamera: {
        name: '',
        url: '',
        img: ''
      },
      contractorData: {
        _id: '',
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        cams: [],
        rdps: [],
        qlinks: []
      },
      pageLength: 5,
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Site',
          field: 'siteName'
        },
        {
          label: 'Region',
          field: 'regionName'
        },
        {
          label: 'Date Added',
          field: 'dateAdded'
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '10%'
        }
      ]
    }
  },
  methods: {
    ////////////////////////////////////////////////////// Camera Deletion Controls ///////////////////////////////////////////
    DeleteCamera() {
      contractor
        .removeResource({
          contractorID: this.contractorData._id,
          type: 'cam',
          resourceID: this.cameraToDelete._id,
          siteID: this.cameraToDelete.siteID
        })
        .then((res) => {
          // set contractor data to edited
          window.Bus.instance.$emit('pageSuccess', {
            header: 'Success',
            detail: 'Camera Successfully Deleted'
          })
          this.$bvModal.hide('delete-modal')
         
          window.Bus.instance.$emit('contractorDataUpdated')
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'Error',
            detail: 'Could not Delete Camera'
          })
        })
    },
    showDeleteModal(camera) {
      this.cameraToDelete = camera
      this.$bvModal.show('delete-modal')
    },
    clearDeleteModal() {
      this.cameraToDelete = {
        name: '',
        url: '',
        img: ''
      }
    }
  },
  computed: {},
  created: function () {},
  async mounted() {
    // Get Contractor Info
    window.Bus.instance.$on('contractorDataLoaded', (data) => {
      this.contractorData = data
      this.rows = data.cams
    })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
.table-icon {
  color: $primary;
  //height: 16px !important;
  //width: 16px !important;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.placeholder {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
}
/* The Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}
</style>
