<template>
  <div>
    <!-- Contractor Info and Location -->
    <div class="row">
      <div class="col-12">
        <b-card class="">
          <div class="row">
            <div class="col-1">
              <b-avatar :text="shortName" size="5rem" variant="primary">
              </b-avatar>
            </div>
            <div class="col-6">
              <b-card-title
                ><h1>
                  {{ contractorData.firstName }} {{ contractorData.lastName }}
                </h1>
                <p>{{ contractorData.email }}</p>
              </b-card-title>

              <span class="align-items-right text-nowrap mt-1">
                <b-button
                  @click="showEditorInSidebar()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-1
                  variant="primary"
                >
                  Edit
                </b-button>
              </span>
              <span class="align-items-right text-nowrap ml-1 mt-1">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-1
                  variant="outline-danger"
                  @click="
                    showDeleteModal(
                      contractorData.contractorID,
                      contractorData.name
                    )
                  "
                >
                  Delete
                </b-button>
              </span>
              <span class="align-items-right text-nowrap ml-1 mt-1">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-1
                  variant="primary"
                  @click="showAddResource()"
                >
                  Add Access
                </b-button>
              </span>
            </div>
            <div class="col-5">
              <div class="d-flex">
                <feather-icon icon="ActivityIcon" class="mr-1" />
                <h6>Status</h6>
              </div>
              <b-badge :variant="statusVariant(contractorData.status)">
                {{ contractorData.status }}
              </b-badge>
              <div class="d-flex mt-2">
                <feather-icon icon="CheckIcon" class="mr-1" />
                <h6>Azure ID</h6>
              </div>
              {{ contractorData.azureID }}
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <b-collapse id="perm-adder">
      <hr />
      <b-row>
        <!-- selection Table -->

        <b-col md="6">
          <b-card>
            <div class="d-flex justify-content-right flex-wrap mr-1 ml-1">
              <div>
                <div
                  class="custom-search d-flex justify-content-right mt-1 mb-1"
                >
                  <div class="d-flex align-items-center">
                    <span class="mr-1">Search</span>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block"
                    />
                  </div>
                  <span class="align-items-right text-nowrap ml-1">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-toggle.collapse-1
                      variant="success"
                      @click="saveContractorPerms()"
                    >
                      Save
                    </b-button>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <vue-good-table
                ref="perms-table"
                :columns="columns"
                :rows="rows"
                :group-options="{
                  enabled: true,
                  collapsable: true
                }"
                @on-selected-rows-change="selectionChanged"
                :select-options="{
                  enabled: true,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :search-options="{
                  searchFn: customSearch,
                  enabled: true,
                  externalQuery: searchTerm
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'name'">
                    {{ props.formattedRow[props.column.field] }}
                  </span>

                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </b-card>
        </b-col>

        <!-- Selected Cards -->
        <b-col md="6"
          ><b-card
            v-for="perm in permission"
            v-bind:key="perm.siteID"
            class="mb-2 ml-1 mr-1"
          >
            <b-card-title>{{ perm.siteName }}</b-card-title>
            <div v-if="perm.cams.length > 0">
              <h5>Cameras</h5>
              <p v-for="cam in perm.cams" v-bind:key="cam.id">
                {{ cam.name }}
              </p>
            </div>
            <div v-if="perm.rdps.length > 0">
              <h5>Remote Desktops</h5>
              <p v-for="rdp in perm.rdps" v-bind:key="rdp.id">
                {{ rdp.name }}
              </p>
            </div>
            <div v-if="perm.qlinks.length > 0">
              <h5>QuickLinks</h5>
              <p v-for="ql in perm.qlinks" v-bind:key="ql.id">
                {{ ql.name }}
              </p>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <hr />
    </b-collapse>
    <!-- Contractor Editor Sidebar -->
    <b-sidebar
      id="sidebar-editor"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetEditor"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Update {{ contractorData.name }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <b-form class="m-2">
          <validation-observer ref="simpleRules">
            <b-row>
              <b-col>
                <b-form-group label="First Name" label-for="contractor-name">
                  <validation-provider
                    rules="required"
                    name="contractor name"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editContractorData.firstName"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Last Name"
                  label-for="contractor-last-name"
                >
                  <validation-provider
                    rules="required|noSpecial"
                    name="LastName"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="editContractorData.lastName"
                      id="contractor-last-name"
                      placeholder="Last Name"
                      min="4"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="row mb-1">
                  <div class="col-10">Enabled</div>
                  <b-form-checkbox
                    checked="true"
                    name="check-button"
                    :options="statusOptions"
                    v-model="editContractorData.status"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  @click.prevent="validateContractorEditor"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </template>
    </b-sidebar>

    <!-- Contractor Table -->

    <!-- Camera Table -->
    <cameras-table />

    <!-- RDP Table -->
    <rdp-table />

    <!-- QuickLink Table -->
    <quick-links-table />

    <!-- Deletion Modal -->
    <b-modal
      id="delete-contractor-modal"
      hide-footer
      centered
      @hidden="clearDeleteModal()"
    >
      <template #modal-title> Delete Contractor</template>
      <div class="d-block text-center">
        <h3>
          Are you sure you want to delete: {{ contractorToDelete.firstName }}
          {{ contractorToDelete.lastName }}?
        </h3>
      </div>
      <div class="mt-2" align="right">
        <b-button
          variant="primary"
          @click="$bvModal.hide('delete-contractor-modal')"
          >Cancel</b-button
        >
        <b-button
          class="ml-1"
          variant="outline-danger"
          @click="DeleteContractor()"
          >Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BAvatar,
  BSidebar,
  BCard,
  BCardBody,
  BCardTitle,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle,
  BForm,
  BRow,
  BCol,
  BDropdownGroup,
  BFormCheckbox,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import vSelect from 'vue-select'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// import Services
import { regionGroup } from '@/services/regionGroup.service.js'
import { contractor } from '@/services/contractor.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

import CamerasTable from '../..//components/contractorEditor/CamerasTable.vue'
import RdpTable from '../../components/contractorEditor/RdpTable.vue'
import QuickLinksTable from '../../components/contractorEditor/QuickLinksTable.vue'

// Declare component
export default {
  name: 'ContractorEditor',
  prop: ['id'],
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BButton,
    VBToggle,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BSidebar,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownGroup,
    BFormCheckbox,
    FeatherIcon,
    BFormInvalidFeedback,
    vSelect,
    CamerasTable,
    RdpTable,
    QuickLinksTable
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      contractorData: {
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        siteIds: [
          {
            azureID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      },
      editContractorData: {
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        siteIds: [
          {
            azureID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      },
      contractorToDelete: {
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        siteIds: [
          {
            azureID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      },
      name: '',
      nameState: '',
      statusOptions: {},
      shortName: '',
      id: '',
      pastSelected: [],
      permission: [],
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Region',
          field: 'group'
        }
      ],
      searchTerm: ''
    }
  },
  methods: {
    ////////////////////////////////////////////////////// Resource Adder Controls ////////////////////////////////////////////////
    resetPermAdder() {
      // send event to contractorManager to refresh contractor list
      this.newContractor = {
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        siteIds: [
          {
            siteID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      }
      this.permission = []
      this.pastSelected = []
      this.$refs['perms-table'].unselectAllInternal()
      this.$root.$emit('bv::toggle::collapse', 'perm-adder')
    },
    showAddResource() {
      this.$root.$emit('bv::toggle::collapse', 'perm-adder')
      this.getAllSites()
    },
    customSearch(row, col, cellValue, searchTerm) {
      if (
        row.group.search(searchTerm) != -1 ||
        row.name.search(searchTerm) != -1 ||
        row.siteID.search(searchTerm) != -1 ||
        row.siteName.search(searchTerm) != -1
      ) {
        return cellValue
      }
    },

    selectionChanged() {
      let rows = this.$refs['perms-table'].selectedRows

      // find differences between selection and passed selection

      let add = rows.filter((x) => !this.pastSelected.includes(x))
      if (add.length > 0) {
        // add to permissions
        //for each permission check if siteID matches
        for (let j = 0; j < add.length; j++) {
          let slotted = false
          for (let i = 0; i < this.permission.length; i++) {
            // if permission matches add it to the proper array
            if (add[j].siteID === this.permission[i].siteID) {
              if (add[j].type === 'Camera') {
                this.permission[i].cams.push({
                  id: add[j].id,
                  name: add[j].name
                })
                slotted = true
              } else if (add[j].type === 'RDP') {
                this.permission[i].rdps.push({
                  id: add[j].id,
                  name: add[j].name
                })
                slotted = true
              } else {
                this.permission[i].qlinks.push({
                  id: add[j].id,
                  name: add[j].name
                })
                slotted = true
              }
            }
          }
          // if no siteIDs match any permissions add new permission with proper resource
          if (slotted === false) {
            if (add[j].type === 'Camera') {
              slotted = true
              this.permission.push({
                siteName: add[j].siteName,
                siteID: add[j].siteID,
                groupName: add[j].group,
                id: add[j].id,
                cams: [{ id: add[j].id, name: add[j].name }],
                rdps: [],
                qlinks: []
              })
            } else if (add[j].type === 'RDP') {
              slotted = true
              this.permission.push({
                siteName: add[j].siteName,
                siteID: add[j].siteID,
                groupName: add[j].group,
                id: add[j].id,
                cams: [],
                rdps: [{ id: add[j].id, name: add[j].name }],
                qlinks: []
              })
            } else {
              slotted = true
              this.permission.push({
                siteName: add[j].siteName,
                siteID: add[j].siteID,
                groupName: add[j].group,
                id: add[j].id,
                cams: [],
                rdps: [],
                qlinks: [{ id: add[j].id, name: add[j].name }]
              })
            }
          }
        }
      } else {
        let remove = this.pastSelected.filter((x) => !rows.includes(x))
        if (remove.length > 0) {
          // remove from permissions
          for (let i = 0; i < remove.length; i++) {
            let resource = remove[i]
            // find permission with resource that needs to be removed
            for (let j = 0; j < this.permission.length; j++) {
              if (this.permission[j].siteID === resource.siteID) {
                if (resource.type === 'Camera') {
                  this.permission[j].cams = this.permission[j].cams.filter(
                    (cam) => cam.id != resource.id
                  )
                } else if (resource.type === 'RDP') {
                  this.permission[j].rdps = this.permission[j].rdps.filter(
                    (rdp) => rdp.id != resource.id
                  )
                } else {
                  this.permission[j].qlinks = this.permission[j].qlinks.filter(
                    (qlink) => qlink.id != resource.id
                  )
                }

                if (
                  this.permission[j].cams.length === 0 &&
                  this.permission[j].rdps.length === 0 &&
                  this.permission[j].qlinks.length === 0
                ) {
                  this.permission.splice(j, 1)
                }
              }
            }
          }
        }
      }
      this.pastSelected = rows
    },

    // Fetch Data For Region Groups and Return Response To Groups
    async getAllSites() {
      try {
        let data = await contractor.getFormattedSitesForContractor(this.id)
        this.rows = data.data
      } catch (e) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: 'Could not retrieve Permissions'
        })
      }
    },

    // Validate form again before submitting
    saveContractorPerms() {
      // eslint-disable-next-line
      contractor
        .addPermissions({ contractorID: this.id, permissions: this.permission })
        .then((response) => {
          if (response.status) {
            this.resetPermAdder()
            this.getContractorBycontractorID(this.id)
            window.Bus.instance.$emit('pageSuccess', {
              header: 'Success',
              detail: 'Contractor Permission Added Successfully'
            })
          } else {
            window.Bus.instance.$emit('pageDanger', {
              header: 'ERROR',
              detail: 'Could not create new Contractor'
            })
          }
        })
    },

    ////////////////////////////////////////////////////// Contractor Deletion Controls ///////////////////////////////////////////
    DeleteContractor() {
      contractor
        .deleteContractor(this.contractorToDelete)
        .then((res) => {
          // set contractor data to edited
          this.$bvModal.hide('delete-contractor-modal')
          window.Bus.instance.$emit('pageSuccess', {
            header: 'Success',
            detail: 'Contractor Successfully Deleted'
          })
          this.id = ''
          this.$router.push('/contractormanager')
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'Error',
            detail: 'Could not Delete Contractor'
          })
        })
    },
    showDeleteModal() {
      this.contractorToDelete = this.contractorData
      this.$bvModal.show('delete-contractor-modal')
    },
    clearDeleteModal() {
      this.contractorToDelete = {
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        siteIds: [
          {
            azureID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      }
    },

    ////////////////////////////////////////////////////// Contractor Editor Controls //////////////////////////////////////////////
    // Fetch Data For Region Groups and Return Response To Groups
    resetEditor() {
      // clear out editor object
      this.editContractorData = {
        firstName: '',
        lastName: '',
        status: false
      }
    },
    validateContractorEditor() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.saveContractorDetails()
        }
      })
    },
    showEditorInSidebar() {
      this.editContractorData = {
        firstName: this.contractorData.firstName,
        lastName: this.contractorData.lastName,
        status: this.contractorData.status === 'Enabled' ? true : false
      }
      this.$root.$emit('bv::toggle::collapse', 'sidebar-editor')
    },
    saveContractorDetails() {
      let details = JSON.parse(JSON.stringify(this.editContractorData))
      details.status = this.editContractorData.status ? 'Enabled' : 'Disabled'
      details._id = this.id
      contractor.updateDetails(details).then((response) => {
        if (response.status) {
          this.$root.$emit('bv::toggle::collapse', 'sidebar-editor')
          this.resetEditor()

          this.getContractorBycontractorID(this.id)
          window.Bus.instance.$emit('pageSuccess', {
            header: 'Success',
            detail: 'Contractor Details Updated Successfully'
          })
        } else {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: 'Could Not Update Contractor Details'
          })
        }
      })
    },
    getContractorBycontractorID(Id) {
      contractor
        .getContractorById(Id)
        .then((data) => {
          this.contractorData = data.data
          this.shortName =
            this.contractorData.firstName.charAt(0) +
            this.contractorData.lastName.charAt(0)
          window.Bus.instance.$emit('contractorDataLoaded', this.contractorData)
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: error.message
          })
        })
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Enabled: 'light-success',
        Disabled: 'light-warning'
        /* eslint-enable key-spacing */
      }
      return (status) => statusColor[status]
    }
  },
  created: function () {},
  async mounted() {
    // Get Contractor Info
    this.id = this.$attrs.id
    this.getContractorBycontractorID(this.id)
    // Get Contractor Info

    window.Bus.instance.$on('contractorDataUpdated', () => {
      if (this.id === this.$attrs.id) {
        this.getContractorBycontractorID(this.id)
      }
    })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
.table-icon {
  color: $primary;
  //height: 16px !important;
  //width: 16px !important;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.placeholder {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
}
</style>
